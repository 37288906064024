import { db } from 'services/firebase';
import { MoverBandejaNotificacion } from './Notification';
import { infoUser, LogAsesor } from './mensajes';
import { MLog } from 'services/FuncionesDev';

function AnalizarUsuarioDB(info, key, red, bot, empresa) {
    /* Fecha en formato timestamp */
    if (info.atributos.ultima_fecha === undefined || info.atributos.ultima_fecha === null) {
        info.atributos.ultima_fecha = 0;
    } else if (Number.isInteger(info.atributos.ultima_fecha)) {
        const tipoNumero = info.atributos.ultima_fecha.toString().length;
        if (tipoNumero === 10) {
            info.atributos.ultima_fecha = new Date(info.atributos.ultima_fecha * 1000).getTime();
        } else {
            info.atributos.ultima_fecha = new Date(info.atributos.ultima_fecha).getTime();
        }
    } else {
        info.atributos.ultima_fecha = new Date(info.atributos.ultima_fecha).getTime();
    }
    return {
        id: `${empresa}-${red}-${bot.id}-${key}`,
        info: {
            id: key,
            red,
            bot: bot.title,
            idbot: bot.id
        },
        dato: info
    };
}

function AgregarEscuchadores(referencia, inicial, dispatch, red, bot, empresa) {
    let nuevos = db.query(db.ref(referencia), db.orderByChild('/atributos/ultima_fecha'));
    if (inicial.fecha !== 0) {
        const fecha = Math.trunc(inicial.fecha / inicial.control);
        nuevos = db.query(db.ref(referencia), db.orderByChild('/atributos/ultima_fecha'), db.startAfter(fecha));
    }
    // Escuchar nuevos USUARIOS
    db.onChildAdded(nuevos, (data) => {
        dispatch({ type: 'MODIFICAR_LISTA_DATOS', modificar: AnalizarUsuarioDB(data.val(), data.key, red, bot, empresa) });
    });
    // Escuchar cambios
    db.onChildChanged(db.ref(referencia), (data) => {
        dispatch({ type: 'MODIFICAR_LISTA_DATOS', modificar: AnalizarUsuarioDB(data.val(), data.key, red, bot, empresa) });
    });
}

function AgregarUsuarios(idEmpresa, redes, bot) {
    return new Promise((fulfill, reject) => {
        db.read.oncequery(
            db.query(
                db.ref(`cliente_chat/${idEmpresa}/${redes.id}/${bot.id}`),
                db.orderByChild('/atributos/ultima_fecha'),
                db.limitToLast(400)
            ),
            (snap) => {
                const lisataUser = [];
                const listaMapa = new Map();
                let ultimo = { fecha: 0, control: 1 };
                snap.forEach((item, i) => {
                    const info = item.val();
                    if (info.atributos !== undefined) {
                        let control = 1;
                        /* Fecha en formato timestamp */
                        if (info.atributos.ultima_fecha === undefined || info.atributos.ultima_fecha === null) {
                            info.atributos.ultima_fecha = 0;
                        } else if (Number.isInteger(info.atributos.ultima_fecha)) {
                            const tipoNumero = info.atributos.ultima_fecha.toString().length;
                            if (tipoNumero === 10) {
                                info.atributos.ultima_fecha = new Date(info.atributos.ultima_fecha * 1000).getTime();
                                control = 1000;
                            } else {
                                info.atributos.ultima_fecha = new Date(info.atributos.ultima_fecha).getTime();
                            }
                        } else {
                            info.atributos.ultima_fecha = new Date(info.atributos.ultima_fecha).getTime();
                        }
                        // agregar al repositorio de lista de usuarios
                        listaMapa.set(`${idEmpresa}-${redes.id}-${bot.id}-${item.key}`, {
                            id: `${idEmpresa}-${redes.id}-${bot.id}-${item.key}`,
                            info: {
                                id: item.key,
                                red: redes.id,
                                bot: bot.title,
                                idbot: bot.id
                            },
                            dato: info
                        });
                        // agregar a la lista ordenada
                        lisataUser.push({
                            id: `${idEmpresa}-${redes.id}-${bot.id}-${item.key}`,
                            ultima_fecha: info.atributos.ultima_fecha
                        });
                        ultimo = { fecha: info.atributos.ultima_fecha === undefined ? 0 : info.atributos.ultima_fecha, control };
                    }
                });
                fulfill({ maplist: listaMapa, usuarios: lisataUser, ultimo, empresa: idEmpresa, red: redes.id, bot });
            }
        );
    });
}

function OrdernarDatos(a, b) {
    const nuevoA = a.ultima_fecha;
    const nuevoB = b.ultima_fecha;

    if (nuevoB === undefined && nuevoA === undefined) {
        return 0;
    }

    if (nuevoA === undefined) {
        return 1;
    }

    if (nuevoB === undefined) {
        return -1;
    }

    if (nuevoA < nuevoB) {
        return 1;
    }
    if (nuevoA > nuevoB) {
        return -1;
    }
    // a must be equal to b
    return 0;
}

function BotDefault(datos, uid) {
    db.read.once(`bots/${datos.id_empresa}/1`, (snap) => {
        const info = snap.val();
        const chatcenter = {
            bots: [{ id: '1', title: info.nombre }],
            redes: []
        };
        if (info.activo.facebook) {
            chatcenter.redes.push({ id: 'facebook', title: 'Facebook' });
        }
        if (info.activo.whatsapp) {
            chatcenter.redes.push({ id: 'whatsapp', title: 'Whatsapp' });
        }
        if (info.activo.webchat) {
            chatcenter.redes.push({ id: 'webchat', title: 'webchat' });
        }
        if (info.activo.instagram) {
            chatcenter.redes.push({ id: 'instagram', title: 'Instagram' });
        }
        if (info.activo.telegram) {
            chatcenter.redes.push({ id: 'telegram', title: 'Telegram' });
        }
        // console.log('chatcenter', chatcenter);
        db.set(`usuarios/${uid}/configuraciones/ChatCenter`, chatcenter);
        // console.log('datos', datos);
    });
}

/* Cargar la lista de bots disponibles */
export function CargarBot(datos, dispatch) {
    db.read.once(`bots/${datos.id_empresa}`, (snap) => {
        const listabots = [];
        snap.forEach((item, i) => {
            listabots.push({ id: item.key, title: item.val().nombre });
        });
        dispatch({ type: 'LISTA_BOTRED', listaBotRedes: listabots });
    });
}

// funcion para cargar desde la base de datos todos los usuarios de los bots y redes sociales solicitadas
export function CargarUsuarios(datos, dispatch, uid) {
    if (datos.configuraciones === undefined || datos.configuraciones.ChatCenter === undefined) {
        BotDefault(datos, uid);
    } else {
        const listaDatos = [];
        datos.configuraciones.ChatCenter.bots.forEach((bot, i) => {
            datos.configuraciones.ChatCenter.redes.forEach((redes, i) => {
                listaDatos.push(AgregarUsuarios(datos.id_empresa, redes, bot));
            });
        });

        Promise.all(listaDatos).then((results) => {
            let listaResultados = [];
            let mapListaDatos = [];
            results.forEach((obj) => {
                listaResultados = listaResultados.concat(obj.usuarios);
                mapListaDatos = new Map([...mapListaDatos].concat([...obj.maplist]));
                AgregarEscuchadores(
                    `cliente_chat/${obj.empresa}/${obj.red}/${obj.bot.id}`,
                    obj.ultimo,
                    dispatch,
                    obj.red,
                    obj.bot,
                    obj.empresa
                );
            });
            MLog.print(mapListaDatos);
            dispatch({ type: 'SET_USUARIOS', listaUsuarios: listaResultados.sort(OrdernarDatos), mapLista: mapListaDatos });
            dispatch({ type: 'LOADING_LISTA_USER', loading: false });
        });
    }
}

/* *
 * Cargar la lista de usuario a mostrar en funcion de las bandeja actual
 * @listaUsuarios array de la lista cargada de la base de datos
 * @listaActual listada de usuarios que se muestran en la lista
 * @bandejaActual bandeja seleccionada
 * @inicio dende donde se empieza a rrecorrer la lista de ussuarios
 * @limiteChat limmite de usuarios a mostrar en la lista de usuarios
 * @SetListaActual Setear lista de datos actual
 * @SetCargarMas setear variable cargar mas
 * @userDispatch dispach del user context
 * */
export function CargarActual(
    listaUsuarios,
    listaActual,
    bandejaActual,
    inicio,
    limiteChat,
    SetListaActual,
    SetCargarMas,
    userDispatch,
    tableHashUser,
    state
) {
    let contador = 0;
    let ultimo = 0;
    let cargarMas = false;
    for (let i = inicio; i < listaUsuarios.length; i += 1) {
        if (
            (bandejaActual.id === 0 &&
                tableHashUser.get(listaUsuarios[i].id).dato.atributos.chatcenter === true &&
                tableHashUser.get(listaUsuarios[i].id).dato.atributos.bandeja === undefined) ||
            (bandejaActual.id === 1 &&
                (tableHashUser.get(listaUsuarios[i].id).dato.atributos.chatcenter === false ||
                    tableHashUser.get(listaUsuarios[i].id).dato.atributos.chatcenter === undefined)) ||
            (tableHashUser.get(listaUsuarios[i].id).dato.atributos.bandeja !== undefined &&
                tableHashUser.get(listaUsuarios[i].id).dato.atributos.bandeja === bandejaActual.title &&
                tableHashUser.get(listaUsuarios[i].id).dato.atributos.chatcenter === true)
        ) {
            if (contador <= limiteChat) {
                listaActual.push({ id: listaUsuarios[i].id, refLista: i });
                ultimo = i;
                contador += 1;
            } else {
                cargarMas = true;
                break;
            }
        }
    }
    // si la llmada del metodo fue desde el contexto
    if (userDispatch === null) {
        state.cargarmas = { ultimo, cargarmas: cargarMas };
        state.listaUsuariosActual = listaActual;
    } else {
        SetListaActual(userDispatch, listaActual);
        SetCargarMas(userDispatch, { ultimo, cargarmas: cargarMas });
    }
}

function copiarObjeto(obj) {
    return obj;
}

// log bandeja
export function LogBandeja(bandejaDestino, usuarioActual, datos, user) {
    return new Promise((fulfill, reject) => {
        // si no tiene log asesor se le asigna uno
        if (usuarioActual.dato.atributos.log_chatcenter === undefined) {
            LogAsesor(user, datos, usuarioActual, bandejaDestino);
        } else if (usuarioActual.dato.atributos.bandeja !== bandejaDestino || usuarioActual.dato.atributos.bandeja === undefined) {
            // si ya tienen solo se agrega al historial de bandejas
            db.read.once(
                `log_chatcenter/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.dato.atributos.log_chatcenter}`,
                (snap) => {
                    const valores = snap.val();
                    let historial = [];
                    // si existe un historiald e banjdeas
                    if ('historialBandejas' in valores) {
                        historial = valores.historialBandejas;
                    }
                    historial.push({
                        bandeja: valores.bandeja === undefined ? '' : valores.bandeja,
                        fecha: db.timestamp,
                        usuario: user.id
                    });
                    // se actualiza la bandeja y el historial de bandejas del log asesor
                    db.update(
                        `log_chatcenter/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.dato.atributos.log_chatcenter}`,
                        {
                            bandeja: bandejaDestino,
                            historialBandejas: historial
                        }
                    );
                    // se actualiza la bandeja del usuarios
                    db.set(
                        `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos/bandeja`,
                        bandejaDestino
                    );
                }
            );
        }
        fulfill();
    });
}

export function CambiarBandeja(bandejaDestino, usuarioActual, datos, user, notificacion) {
    // agregar log
    LogBandeja(bandejaDestino, copiarObjeto(usuarioActual), datos, user);
    // borrar de bandeja de usuarios
    if (usuarioActual.dato.atributos.bandeja !== undefined) {
        db.set(
            `bandeja_usuario/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.dato.atributos.bandeja}/${usuarioActual.info.id}/`,
            null
        );
    }
    // agregar la bandeja a los atributos
    db.set(
        `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos/bandeja`,
        bandejaDestino
    );
    db.set(
        `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos/chatcenter/`,
        true
    );
    // agregar a usuario bandeja a la nueva bandeja
    MLog.print('mover bandeja', usuarioActual);
    MLog.print('mover bandeja infouser', infoUser(usuarioActual.info, usuarioActual.dato));
    db.set(
        `bandeja_usuario/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${bandejaDestino}/${usuarioActual.info.id}/`,
        {
            fecha_ingreso: db.timestamp,
            nombre: infoUser(usuarioActual.info, usuarioActual.dato).user
        }
    );
    if (notificacion) {
        MoverBandejaNotificacion(
            datos,
            bandejaDestino,
            usuarioActual.info.idbot,
            usuarioActual.info.id,
            user,
            usuarioActual.info.red,
            usuarioActual.info.red
        );
    }
}

/* ---cargar etiqetas */
export function CargarEtiquetas(uid, dispatch) {
    db.read.once(`usuarios/${uid}/`, (snap) => {
        const datos = snap.val();
        db.read.on(`/etiquetas/${datos.id_empresa}`, (snap) => {
            const listaetiquetas = {};
            if (snap.val() !== null) {
                snap.forEach((item, i) => {
                    listaetiquetas[item.key] = { id: item.key, nombre: item.val().nombre, color: item.val().color };
                });
                dispatch({ type: 'ETIQUETAS_LISTA', listaEtiqetas: listaetiquetas });
            }
        });
    });
}

/*  --- Agregar nueva etiqueta */
export function NuevaEtiqueta(datos, etiqueta, toggleOpen, setCargando, valor, setListaValor) {
    const idEtiqueta = db.push(`/etiquetas/${datos.id_empresa}`, etiqueta);
    setCargando(false);
    toggleOpen(false);
    valor.push({ ...etiqueta, id: idEtiqueta });
    setListaValor(valor);
}

/*  ---Guardar etiqueta */
export function GuatadarEtiquetasUsuario(datos, arregloEtiquetas, usuarioActual, toggleOpen, setCargando) {
    const objeto = {};
    arregloEtiquetas.forEach((item, i) => {
        objeto[item.id] = true;
    });
    if (Object.entries(objeto).length > 0) {
        MLog.print('etieurtas save');
        db.set(`cliente_chat/${datos.id_empresa}/${usuarioActual.red}/${usuarioActual.idbot}/${usuarioActual.id}/etiquetas/`, objeto);
    }
    toggleOpen();
}
