// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoRM = ({ tipo }) => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="107px" height="44px" viewBox="0 0 139 76">
            <image
                id="image0"
                width="139"
                height="76"
                x="0"
                y="0"
                href={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIsAAABMCAYAAABd9ETzAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
 AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAO
 QElEQVR42u2dfaxkZX3HP8+ZuZfl7gJ32Yk2SosQS5BaHCRauje0FapNCrqmLtR2S0GRdRgxUxlt
 QzCF0qY11nEZiMMQX6iCKQ1bLRo02kJrzCImirO+sYU0SKgrbmZfYHfZO+ec5/n1j3POzJm583Zm
 5pyZnd5v8uTOOc95nvM85/zu9/fyvBy1fMmjwkShUPolUs4Lgy50gOeAJ4HvAPuAnwD/W6/lJtym
 dtzz2c+fCfwJMNH7HDhwQP1i/36xLEsBj1bKpX2Tbnu+ULwS+DX/UE26D0AKeKBSLh3uzEhPujMR
 sAC81k9Xh87/JJOtfgr4Qr2WOx7TvV8F3Blz/58ELs4XilTKpYlUmC8ULeCrMbY5wGPAGmGxErhx
 VPwGUAFeyGSrdwUnM9nqJO8RK3P5eGO+ULx4goICcG/4nFIqttQNsygsATYBH8xkqy9lstXb6rXc
 tNszCm6aRCUhdvrjaXZmloUlwGnA7Zls9clMtrp12o2JiOvyheJp41ZSKZfIF4p5/1m0IUlmmabN
 EhUXAXsy2eongL+M2wieIHYB7xu1sK9+UsDONZl9XmwcOBmYpRMfBr4NE7dj4sK7ofnSI8NXP6/G
 s+XaoGBdWIbASiZbfRZYzmSryT2t0bAxXyjeOqahexd9tMC6gTsYrwFqwGmZbHXWWWZnvlBcjFoo
 XyiSLxTPArZ1y1/3hqLhbGAvkJpxb+ks4NyohXw22tHzggRVEJz8wgIew+zLZKupGWYXC/j3Ecve
 0isjsFnWmSUaXgt8tl7LzbI6OitfKF4cxdDNF4rvBc7od826sIyGazPZ6mUzro5ujmjofqBvrlLx
 pS6YJ2EB+DrMtEt9db5QzAzDLvlC8Wy82FJPqBhTN8ybsCxmstUvzDC7pPHZZQiB2UXv9+Zh3Rsa
 G1dnstWxQ+wx4nqg50i07y5vAK4cVJESWReWMXEK8OlpN6IPXpEvFP+8V6YvRH+NN4WjPxK2WU6m
 saEo2JbJVjfGOB9mXNyZLxS/COgww/iq6QwGGbY+lFID9NRkMY/MArABuGzajeiDzcBrOlWRf7wV
 OH3YitbV0GRw87QbMABf6XF+aOs86XB/DGpIQC08BTxCuzUveBR7EXABsBTLK2jh9zLZ6lK9lns5
 5vuMigvyhWK2Ui7VoKmCzgXeMe2G9UIsNotYG/bWa7mPhM9lslUClzaTrf4K8I/An8Xcv9/OZKuP
 zrArvQOoBTPh8oXiJ6NWMJdTFEKCQr2We6Fey10D3BDzbd80w4IC3tycQFAU8LYohed+1LlDaD4D
 fDzG223vdtIYE0sSiT55L18o3un/vB04NUpZpRRY1vyH+0ODfrfHeJsLup0UkVgSIwgLcI2/xOPG
 UQpbIv8/wv31Wo56LXeC+NTRQiZbPaXzZGzM0udB98GZwD3AlqgFlc8CCXpDUf4bhrl2pP+u//QL
 TtpaS+G9hP1tLRyNAQb3XGTUCUk7Rykk4KmhmPrTibQSt1cz1vwccHIc2IDBe7mThMJbf9QGY8yk
 2+8/lmQXHDQjuAl5RGnEZe3LD27eeV468juvGflhpYhPJa5pVKzMkjAspRK7ry8sHjwRiKpqwtpj
 ZE3ym6MWHKKhx9acjEtYYqm1N5oPLClmUWJHfwQyID86vjJ+FV3hAgc7TxqtY7lZ4swSGLgJ3a6H
 zdLs/pDVjP6QMtnqB2Psn12v5db8N8T2UpO2WUhaDdHDwO17fxmQ1b/xQeg/k63+Lt7WF3Hhx12b
 OEc2S1IqCCCNCQuLDEFpE3GfU5ls9Qrg4Zj7t7vbybi8oWmooUS9ISUOExGANkZRp2ay1fNY6zZt
 xhtVfT8jBKFGwPfDA5gBzDQYIAY01zonpYYkbLOsdTIHFJfu51RqmyK1jenjiW4DiRITsyQuhD6j
 JDXynEY7PTMlqrD4h8oSSE06vhYZj/lDCWsQ20udhhqyrOQMXOnpDQ0yckPXtR0KgkJNXVZ6G87z
 EmexiCc41Qtp0V0iuBFC/NLlyFJTn625ireJXlfMS7gfWoOJSSAtplu4P9waurvD/QTKmvqiga8B
 PWf2z5XNkqSBi+nuDXUZUBmySoMxC9Oc++ACO/vNkJunoFyS0yp9ZunZ+y6/umWHR6kFlYonnD4k
 vlGv5Q72uyAuBphGUC5Zm8V084ak7Q9dr5CeOcRlEwyBei13ZbfYSlsL42vfcWBjUn1VSjlKqcEr
 FyeEFrOIN2LsiYD0FtlubNKWL0h6asxyA8CgSdpxMcvC4uJ9vPzym4E3J9DX5zdt2vQwcFNSqsgS
 4yLGQcRFxAFxQFwwoaRd0I6XTJDcLsnP6zs4GRueBv5pmO02xJi4khKRDyXQVzHGXIpK1u20vJes
 Q0Lhp0CIjOsLkvb/9kgmSA5I4szSAN4AuMMs/YhRWKzq3bsex/PG4sRXq3fveg6wEl0Yb7RN28cl
 mvOYpP280H7cvKYTBjGJC8vWei23OuzFCbi424FDeGuuJ41fVMqlbZCsJwRNZnFaTKKDpBHttlKT
 aUJJdDvjBOwiiRq4f1Gv5Z6MsttTXLP7g2BfpVyKc8XC3wQ/El9kFlYliG6lnraJbqmtzmvEV2HJ
 qaE76rVcGQYbtWFIjAmaH2Z4AHh8wv2tVcqle8evZjS0XOd+zDyItlWoAklEDWngqnot9+VRCsc1
 rTJAaBuw6/AM70nhyvD3i6yE1VD3saGuGDA7TlrCEi+zKDELGQsxbwBGEpYkgmf+C30mXyh+BG8T
 gHFxc6Vc+nn7o0hYWBC3FWDr+QyHmGbZNIzjYhYRsZaMWEspZRxA37bljfc/mNL2vgN7r49aUwzt
 WwufXXYBeeCcMao6grdqsQ2JG7iinTZ3uS1mEsRWtG+PdKawzRIyhj0tMQl4I5hiLRpJLSPWKSkl
 DZAGiI2Sxn0H9l7PlgvLkWqN28ANUCmXqJRLGnjXmA/likq5NLS3FxesVnyk09vpHUdpekxtZVuC
 JhOyCUQtOSa9DGqDhbhKmVUwNkpslLHB2JdsufDuDx38YYEzX/eJ4euNaWF8N8bybYwfAD8Y8TF8
 uVIuPd5tK9TkvaFOAel0j9vcacd3o52+ybgn9otafEnUAijlS44EvrjxbBqtPXpSoJSLSmuxTtFi
 bdQmvWxM+gzBSi8oY6sWk3gJbSPaRkwD0fZtF73+pxx66sMTEdBJIzB2K+XSm4AXIxa3gR29PsqZ
 +DZhntoIY1R93irnHH3+W2bp128U3fgSyGUgKBFvNwfRylvWrBUiSmFAxAJjIQaFwbNJPENZiQGC
 eI7xbCJxW7aRmDOeNV96jBnecDD0om8C7o9Q9Do/ZtMVyQflMLSncSIMrW4c3nPpi0ee+P3LwboX
 LEQpSzAWSjxhEbEUruWNIzmWxxpt9ojHItJAjA3aQXQD0Q3vtxswTAPEfsvp5966kuiTi4hQ7OXb
 Qxb5H+DBUb+AFgdin9J25InLc5t/62t1xNwKGo89dNPF9n5r2pkkYA7vnPjXto51yOvSiDaAeXjT
 2Tefc+y5Tx6d9kPthlDs5XoGx14MsFIpl/rS/BSYJV5svuSbHP7uH35UGfseZWyUaYSS3ZYwvh3i
 OhjXwegGRtuI28C4HosY314xrhP628C49hZce6Y3kPO9o2eAWwdcekelXPrlIFaZuz3lDj/h7al3
 6Ht/lFfS+I4SG4zjGat4KkfEExJ0I2S4NkKGrH/O+Mc6fNwI53986ZXvPf/UzDVxd2tk+ALwMaCX
 LfIz4A5gYl+anxQSmw+x5eIvKnGP/Q5i/7eSBhgbdAOjG+DaiNsShIA9pGmntPIJH7stgWr9df71
 RP1+Tl3ePn6jY4DPLobeE6RuqZRLMoytMnfMEuDg93fIob05F914R8AIxn/JxmcJExisPlsY0/AF
 xvZd8tY1AbN419gY3UC8/As2LG8vnDiym8WNVyTVvUjwjd0fs3Yt9r9VyqUHYThWmVthAchk7+Hg
 Dz/wtLj25S32CGwQu6lmjHYQ12cQn33EbcZVWnaLzzBevoMxzTr+fnHT2zfbxx9JsntDI2Tsdn4d
 JBfF+0n641SJLvCp127kzNeVOPTT4mObz/u7Twv6hrUeTvi45fUE+RjPO2o79r0rEQ3GIKKXxOj7
 gHcm2b8o8JnjRL5QvAp4CHh/pVz6ZaRK5s0b6sShp4osb93N4ac/ulNc+0eBTdKyW1ZbdkrAOG4Q
 Z2kxEWF7xglsGLtpz6DtbamFS9+SdP+iolIu7Qb+GfhM1JjKHHzoYTCOPL6d08+5Bczq20V4FuMq
 b9Nhtz22Yrz4i4gbMEaLQZoR3OBaE2IjCWbrfRNYSKVX0O6eaXR1IHz75U+n3Y5hMLWFgy89+w+8
 +LPSc+KuXmt0OIbS8naMsTFuK+4SZhrjhOItrm/XaNtXWc3R33QqvXKXdveQSs9mgHcc93iuDdxO
 nParBY79/FP3o+1/CYxVMY7n4UijzZ0W8YUp8JpMON7iRXx7IJ9KryzPKrOcTJiqsBx9vszGV+Ww
 j76wA904IMaPnbgO4thgWh5R21hQM1DnDrO+OAU8k0qvqFlll5MFU98b4/j+Ks7Lj2gxzg5PBXlh
 fjF2K8TfdKn9uItvl0RABrh2nV3Gw9SFBWDD5qtYPfzQf4i2H2iLpZiwunHG3aXgc6n0yqun3deT
 GTMhLKuHHwLAPvbINeI29gVTEbzZeJFZpBcU3nzYdYyImRCWMIxpvLtlsE58YvVVytr6tlns98mA
 mXto2tmzF/iruOpXSn3uvPMzUac3rgMvKHdw7FrasUifLbqGxC7gPcArmDC9KFj62N/+1+53Xf36
 aWwBd7Kg65NJA3EYfWNN79fuHieVXrmQGJhP6+Py1j+46HwRvsXk9VzSyzVWgaN031N/VCzQ4/39
 H9lflL4ZlTwHAAAAXGVYSWZJSSoACAAAAAMAEgEDAAEAAAABAAAAMQECAAwAAAAyAAAAaYcEAAEA
 AAA+AAAAAAAAAEZvdG9zIDIuNy4zAAIAAqAJAAEAAACLAAAAA6AJAAEAAABMAAAAAAAAADwt/BgA
 AAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMTItMTVUMDU6NTY6MzErMDA6MDCHfufRAAAAJXRFWHRk
 YXRlOm1vZGlmeQAyMDIyLTEyLTE1VDA1OjU2OjMxKzAwOjAw9iNfbQAAABJ0RVh0ZXhpZjpFeGlm
 T2Zmc2V0ADYyMBqjeAAAABh0RVh0ZXhpZjpQaXhlbFhEaW1lbnNpb24AMTM5FMOrCgAAABd0RVh0
 ZXhpZjpQaXhlbFlEaW1lbnNpb24ANzY2O7+PAAAAGXRFWHRleGlmOlNvZnR3YXJlAEZvdG9zIDIu
 Ny4zd+p9vwAAAABJRU5ErkJggg==`}
            />
        </svg>
    );
};

export default LogoRM;
